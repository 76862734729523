import { apiSlice } from "../apiSlice";

export const cretipxxApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createType: builder.mutation({
      query: (parameters) => ({
        url: `tipos`,
        method: "POST",
        body: parameters,
      }),
    }),
    createCompanyAreaSerieType: builder.mutation({
      query: (parameters) => ({
        url: `serieportipos`,
        method: "POST",
        body: parameters,
      }),
      invalidatesTags: ["reloadTable"],
    }),
    createCompanyAreaSerieTypeParents: builder.mutation({
      query: (parameters) => ({
        url: `padreportipos`,
        method: "POST",
        body: parameters,
      }),
      invalidatesTags: ["reloadTable"],
    }),
    getTypeParent: builder.query({
      query: (parameters) => ({
        url: `/getTypeParent`,
        method: "GET",
        params: `filters[EMPNITXX]=${parameters.EMPNITXX}&filters[AREAIDXX]=${parameters.AREAIDXX}&filters[SERIEIDX]=${parameters.SERIEIDX}`
      }),
    }),
    getTypeParentOptions: builder.mutation({
      query: (parameters) => ({
        url: `/getTypeParent`,
        method: "GET",
        params: `filters[EMPNITXX]=${parameters.EMPNITXX}&filters[AREAIDXX]=${parameters.AREAIDXX}&filters[SERIEIDX]=${parameters.SERIEIDX}&filters[BANAPLPA]=${parameters.BANAPLPA}`
      }),
    }),
    getParentsByTypes: builder.mutation({
      query: (parameters) => ({
        url: `/padreportipos`,
        method: "GET",
        params: `filters[GEST0016.EMPNITXX]=${parameters.EMPNITXX}&filters[GEST0016.AREAIDXX]=${parameters.AREAIDXX}&filters[GEST0016.SERIEIDX]=${parameters.SERIEIDX}&filters[GEST0016.TIPOIDXX]=${parameters.TIPOIDXX}`
      }),
    }),
    getData: builder.query({
      query: (parameters) => ({
        url: `/serieportipos/${parameters.EMPNITXX}/${parameters.AREAIDXX}/${parameters.SERIEIDX}/${parameters.TIPOIDXX}`,
        method: "GET",
      }),
    }),
    updateTipoEmpresa: builder.mutation({
      query: (parameters) => ({
        url: `tipoEmpresa`,
        method: "POST",
        body: parameters,
      }),
      invalidatesTags: ["reloadTable"],
    }),
  }),
});

export const {
  useCreateTypeMutation,
  useCreateCompanyAreaSerieTypeMutation,
  useCreateCompanyAreaSerieTypeParentsMutation,
  useLazyGetTypeParentQuery,
  useGetTypeParentOptionsMutation,
  useGetParentsByTypesMutation,
  useLazyGetDataQuery,
  useUpdateTipoEmpresaMutation,
} = cretipxxApiSlice;
