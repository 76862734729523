import React, { useState } from "react";
import Navbarxx from "../components/Navbarxx";
import Tabsproc from "../components/Tabsproc";
import Tabsserx from "../components/Tabsserx";
import Tabsmtdt from "../components/Tabsmtdt";
import Tabstipx from "../components/Tabstipx";
import Tabsasmt from "../components/Tabsasmt";
import { Container, Row, Col, Image, Button, Modal } from "react-bootstrap";

const AdminPage = () => {
  const [tracking, setTracking] = useState("");
  const [show, setShow] = useState(false);
  let trackingComponent;

  let oComponent;
  switch (tracking) {
    case "PROCESO":
      trackingComponent = <Tabsproc />;
      break;
    case "SERIE":
      trackingComponent = <Tabsserx />;
      break;
    case "TIPO":
      trackingComponent = <Tabstipx />;
      break;
    case "METADATO":
      trackingComponent = <Tabsmtdt />;
      break;
    case "ASOCIAR METADATO":
      trackingComponent = <Tabsasmt />;
      break;
    default:
      trackingComponent = null;
      break;
  }
  return (
    <>
      <Modal show={show} onHide={() => setShow(false)} className="modal-xl">
        <Modal.Header closeButton>
          <Modal.Title>CREAR {tracking}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{oComponent}</Modal.Body>
      </Modal>

      <Navbarxx path={"admin"} />
      <Container>
        <Row className="justify-content-md-center">
          <Col xxl={4} xl={6} md={10} className="align-self-center mt-1">
            <Image
              src="https://gestordocumental.repremundo.com.co/sites/default/files/Genio%20documental1-33.png"
              width="95%"
            />
          </Col>
        </Row>
        <Row className="mt-2">
          <Col>
            <Button
              className="w-100"
              onClick={() => {
                setTracking("PROCESO");
              }}
            >
              Proceso
            </Button>
          </Col>
          <Col>
            <Button
              className="w-100"
              onClick={() => {
                setTracking("SERIE");
              }}
            >
              Serie
            </Button>
          </Col>
          <Col>
            <Button
              className="w-100"
              onClick={() => {
                setTracking("TIPO");
              }}
            >
              Tipo
            </Button>
          </Col>
          <Col>
            <Button
              className="w-100"
              onClick={() => {
                setTracking("METADATO");
              }}
            >
              Metadato
            </Button>
          </Col>
          <Col>
            <Button
              className="w-100"
              onClick={() => {
                setTracking("ASOCIAR METADATO");
              }}
            >
              Asociar Metadato
            </Button>
          </Col>
        </Row>
        <br />
        <Row className="mt-3">
          {trackingComponent}
        </Row>
      </Container>
    </>
  );
};

export default AdminPage;
