import React, { useState } from "react";
import { Button, Col, Form, InputGroup, Row, Spinner } from "react-bootstrap";
import {
  useCreateTypeMutation,
  useCreateCompanyAreaSerieTypeMutation,
  useCreateCompanyAreaSerieTypeParentsMutation,
  useGetTypeParentOptionsMutation,
  useLazyGetDataQuery,
  useUpdateTipoEmpresaMutation
} from "../../libs/redux/slices/cretipxxSlice/cretipxxApiSlice";

import Toast from "react-bootstrap/Toast";
import {
  useGetMetadataByCompanyAreaSerieTypeMutation
} from "../../libs/redux/slices/filterSlice/filterApiSlice";

import { setDictionaryErrors } from "../../libs/redux/slices/errorSlice/errorSlice";
import { dictionaryErrors } from "./configxx";

import { useDispatch } from "react-redux";
import Reutilxx from "./Reutilxx";
import Parent from "./Parent";
import { useEffect } from "react";

const Cretipxx = ({ onClose, row, isCreating, isEdit }) => {
  const dispatch = useDispatch();
  dispatch(setDictionaryErrors({ dictionaryErrors }));
  const [insertType, { isLoading: isLoadingType }] = useCreateTypeMutation();
  const [insertCompanyAreaSerieType, { isLoading: isLoadingCompanyAreaSerieType }] =
    useCreateCompanyAreaSerieTypeMutation();
    const [updateTypeCompany] =
    useUpdateTipoEmpresaMutation();
  const [insertCompanyAreaSerieTypeParents, { isLoading: isLoadingCompanyAreaSerieTypeParents }] =
    useCreateCompanyAreaSerieTypeParentsMutation();
  const [getTypeParentOptionsMutation] = useGetTypeParentOptionsMutation();
  const [getMetadataByCompanyAreaSerieTypeMutation] = useGetMetadataByCompanyAreaSerieTypeMutation();
  const [validated, setValidated] = useState(false);
  const [typeDescription, setTypeDescription] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [getData] = useLazyGetDataQuery();
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  const [descriptionPath, setDescriptionPath] = useState("");

  const [formRows, setFormRows] = useState([
    { company: "", area: "", serie: "", homologation: "", documentParent: false, parentControls: [new Parent("", "")] },
  ]);

  const addRow = () => {
    const newRow = { company: "", area: "", serie: "", homologation: "", documentParent: false, parentControls: [new Parent("", "")] };
    setFormRows([...formRows, newRow]);
  };

  const removeRow = (index) => {
    const updatedRows = formRows.filter((_, rowIndex) => rowIndex !== index);
    setFormRows(updatedRows);
  };

  const handleRowChange = (index, key, value) => {

    const updatedRows = [...formRows];
    updatedRows[index][key] = value;
    setFormRows(updatedRows);
    if (!isCreating) {
      if (key === "documentParent" && value && updatedRows[index].parentControls.length === 0) {
        addParent(index)
      } else if (key === "documentParent" && !value) {
        removeParent(index, 0)
      }
    }
  };

  const addParent = (rowIndex) => {
    const updatedRows = [...formRows];
    updatedRows[rowIndex].parentControls.push(new Parent("", ""));
    setFormRows(updatedRows);
  };

  const removeParent = (rowIndex, parentIndex) => {
    const updatedRows = [...formRows];
    updatedRows[rowIndex].parentControls.splice(parentIndex, 1);
    setFormRows(updatedRows);
  };

  const handleParentChange = async (rowIndex, parentIndex, key, value) => {
    const updatedRows = [...formRows];
    const parentControl = updatedRows[rowIndex].parentControls[parentIndex];

    parentControl[key] = value;

    if (key === "AREAIDPA") {
      parentControl["EMPNITPA"] = updatedRows[rowIndex].company;
    }

    if (key === "SERIEIPA") {
      const parentTypeData = value
        ? await FetchParentTypeOptions({
            EMPNITXX: updatedRows[rowIndex].company,
            AREAIDXX: parentControl.AREAIDPA,
            SERIEIDX: value,
            BANAPLPA: "NO",
            REGESTXX: "ACTIVO"
          })
        : [];
      parentControl.TYPEOPTS = parentTypeData;
      parentControl.TIPOIDPA = "";
      parentControl.METAOPTS = [];
      parentControl.METIDXXX = "";
    }

    if (key === "TIPOIDPA") {
      const parentMetaData = value
        ? await FetchParentMetadataOptions({
            EMPNITXX: updatedRows[rowIndex].company,
            AREAIDXX: parentControl.AREAIDPA,
            SERIEIDX: parentControl.SERIEIPA,
            TIPOIDXX: value,
            METIDXXX: "null",
          })
        : [];
      parentControl.METAOPTS = parentMetaData;
      parentControl.METIDXXX = "";
    }

    setFormRows(updatedRows);
  };

  const resetForm = () => {
    setTypeDescription(""); // Reset tipo descripción
    setDescriptionPath(""); // Reset descripción del path

    // Resetear las filas de la grilla
    setFormRows([
      { company: "", area: "", serie: "", homologation: "", documentParent: false, parentControls: [new Parent("", "")] },
    ]);
  };

  const formatFormRows = (formRows) => {
    return formRows.map((r) => ({
      EMPNITXX: r.company,
      AREAIDXX: r.area,
      SERIEIDX: r.serie,
      HOMDIAXX: r.homologation,
      BANAPLPA: r.documentParent ? "SI" : "NO",
      GEST0016: r.parentControls.map((parent) => ({
        EMPNITPA: parent.EMPNITPA,
        AREAIDPA: parent.AREAIDPA,
        SERIEIPA: parent.SERIEIPA,
        TIPOIDPA: parent.TIPOIDPA,
        METIDXXX: parent.METIDXXX,
      })),
    }));
  };



  const handleSubmit = async (event) => {
    setIsSubmitting(true);
    event.preventDefault();
    const form = event.currentTarget;

    if (form.checkValidity()) {
      if (!isCreating) {
        try {
          // Al momento de enviar los datos al servicio:
          const formattedData = formatFormRows(formRows);

          await updateTypeCompany({ RELATION: formattedData, TIPOIDXX: row.TIPOIDXX, TIPODESX: typeDescription });
          setToastMessage("Se ha actualizado con exito!");
          setShowToast(true);
        } catch (error) {
          console.log("error");
          setToastMessage("Error al actualizar: " + error.message);
          setShowToast(true);
        }
      } else {
        const response = await insertType({
          TIPODESX: typeDescription,
          TIPOPATH: descriptionPath,
        }).unwrap();
        for (let i = 0; i < formRows.length; i++) {
          const row = formRows[i];
          if (row.company && row.area) {
            try {
              await saveCompanyAreaSerieType(row, response.TIPOIDXX);
            } catch (error) {
              console.error(`Error al guardar la fila ${i + 1}:`, error);
              setToastMessage("Error al guardar: " + error.message);
              setShowToast(true);
            }
          }
        }
        setToastMessage("Se ha creado el tipo documental correctamente");
        setShowToast(true);
      }
      setValidated(true);
      onClose(false);
    } else {
      event.preventDefault();
      setValidated(true);
    }
    setIsSubmitting(false);
  };

  const saveCompanyAreaSerieType = async (row, typeID) => {
    await insertCompanyAreaSerieType({
      EMPNITXX: row.company,
      AREAIDXX: row.area,
      SERIEIDX: row.serie,
      TIPOIDXX: typeID,
      HOMDIAXX: row.homologation,
      ISPARENT: row.documentParent ? "SI" : "NO",
    })
      .unwrap()
      .then(async () => {
        if (row.documentParent) {
          await saveTypeParents(row, typeID);
        }
      })
      .catch((error) => {
        console.error("Error al guardar la relación:", error);
      });
  };


  const saveTypeParents = async (row, typeID) => {
    await insertCompanyAreaSerieTypeParents({
      EMPNITXX: row.company,
      AREAIDXX: row.area,
      SERIEIDX: row.serie,
      TIPOIDXX: typeID,
      PARENTSX: row.parentControls,
    })
      .unwrap()
      .catch((error) => {
        console.error("Error al guardar los padres:", error);
      });
  };

  const FetchParentTypeOptions = async (params) => {
    try {
      const response = await getTypeParentOptionsMutation(params).unwrap();
      return response.data || [];
    } catch {
      return [];
    }
  };

  const FetchParentMetadataOptions = async (params) => {
    return await getMetadataByCompanyAreaSerieTypeMutation(params)
      .unwrap()
      .then((response) => {
        return response.data;
      })
      .catch(() => {
        return [];
      });
  };

  useEffect(() => {
    setTypeDescription(row.TIPODESX);
    setDescriptionPath(row.TIPOPATH);
  }, [row]);

  useEffect(() => {
    if (row?.TIPOIDXX) {
      // Llamar al servicio para obtener los datos
      getData({ EMPNITXX: null, AREAIDXX: null, SERIEIDX: null, TIPOIDXX: row.TIPOIDXX })
        .unwrap()
        .then((response) => {
          const fetchedData = response.data || [];

          // Construir formRows a partir de los datos
          const updatedFormRows = fetchedData.map((item) => ({
            company: item.EMPNITXX,
            area: item.AREAIDXX,
            serie: item.SERIEIDX,
            homologation: item.HOMDIAXX || "",
            documentParent: item.BANAPLPA === "SI",
            parentControls: item.PARENTSX?.map((parent) => ({
              EMPNITPA: parent.EMPNITPA,
              AREAIDPA: parent.AREAIDPA,
              SERIEIPA: parent.SERIEIPA,
              TIPOIDPA: parent.TIPOIDPA,
              METIDXXX: parent.METIDXXX,
              TYPEOPTS: [],
              METAOPTS: [],
            })) || [],
          }));

          setFormRows(updatedFormRows);

          // Actualizar campos de nivel superior
          setTypeDescription(fetchedData[0]?.TIPODESX || "");
          setDescriptionPath(fetchedData[0]?.TIPOPATH || "");
          setIsDataLoaded(true);
        })
        .catch((error) => {
          console.error("Error al cargar los datos:", error);
        });
    }
    // eslint-disable-next-line
  }, [row]);

  useEffect(() => {
    if (isDataLoaded) {
      formRows.forEach((row, rowIndex) => {
        row.parentControls.forEach((parent, parentIndex) => {
          if (parent.SERIEIPA) {
            FetchParentTypeOptions({
              EMPNITXX: row.company,
              AREAIDXX: parent.AREAIDPA,
              SERIEIDX: parent.SERIEIPA,
              BANAPLPA: "NO",
              REGESTXX: "ACTIVO"
            }).then((typeOpts) => {
              handleParentChange(rowIndex, parentIndex, "TYPEOPTS", typeOpts);
            });
          }

          if (parent.TIPOIDPA) {
            FetchParentMetadataOptions({
              EMPNITXX: row.company,
              AREAIDXX: parent.AREAIDPA,
              SERIEIDX: parent.SERIEIPA,
              TIPOIDXX: parent.TIPOIDPA,
              METIDXXX: "null",
            }).then((metaOpts) => {
              handleParentChange(rowIndex, parentIndex, "METAOPTS", metaOpts);
            });
          }
        });
      });

      // Marcar datos como cargados
      setIsDataLoaded(false);
    }
    // eslint-disable-next-line
  }, [isDataLoaded]);

  return isLoadingType || isLoadingCompanyAreaSerieType || isLoadingCompanyAreaSerieTypeParents? (
    <Spinner animation="border" role="status">
      <span className="visually-hidden">Loading...</span>
    </Spinner>
  ) : (
    <Form noValidate validated={validated} onSubmit={handleSubmit}>
      <Row md={12} className="mt-3">
        <Form.Group as={Col} md={4}>
          <Form.Label>Tipo*</Form.Label>
          <InputGroup>
            <Form.Control
              value={typeDescription}
              type="text"
              required
              disabled={!isEdit && !isCreating}
              onChange={(e) => {
                setTypeDescription(e.target.value);
              }}
            />
            <Form.Control.Feedback type="invalid">Debe Diligenciar el Nombre del Tipo</Form.Control.Feedback>
          </InputGroup>
        </Form.Group>
      </Row>
      <Row md={12} className="mt-3">
        <Form.Group as={Col} md={4}>
          <Form.Label>Descripcion del Path*</Form.Label>
          <InputGroup>
            <Form.Control
              value={descriptionPath}
              type="text"
              required
              onChange={(e) => {
                setDescriptionPath(e.target.value);
              }}
              minLength={8}
              disabled={!isCreating}
              maxLength={8}
            />
            <Form.Control.Feedback type="invalid">Debe Diligenciar la Descripcion del Path</Form.Control.Feedback>
          </InputGroup>
        </Form.Group>
      </Row>
      <br />
      {formRows.map((row, index) => (
        <div key={index} className="border p-3 mb-3">
          <Row>
            <Reutilxx
              company={row.company}
              handleChangeCompany={(value) => handleRowChange(index, "company", value)}
              area={row.area}
              handleChangeArea={(value) => handleRowChange(index, "area", value)}
              serie={row.serie}
              handleChangeSerie={(value) => handleRowChange(index, "serie", value)}
              removeAllParentDetail={() => handleRowChange(index, "parentControls", [new Parent("", "")])}
              isSubmitting={isSubmitting}
              isEdit={isEdit}
              isCreating={isCreating}
            />
            <Col md={1} className="d-flex align-items-center">
              <Button
                variant="outline-primary"
                size="sm"
                className="ms-2 rounded-circle"
                onClick={addRow}
                disabled={!isEdit && !isCreating}
                style={{ width: "30px", height: "30px", color: "white", backgroundColor: "#007bff", border: "none" }}
              >
                +
              </Button>
              <Button
                variant="outline-secondary"
                size="sm"
                className="ms-2 rounded-circle bg-secondary text-white"
                onClick={() => removeRow(index)}
                disabled={formRows.length === 1 ||  (!isEdit && !isCreating)}
                style={{ width: "30px", height: "30px", border: "none" }}
              >
                ×
              </Button>
            </Col>
            <Form.Group as={Col} md={3}>
              <Form.Label>Homologación DIAN</Form.Label>
              <Form.Control
                type="text"
                value={row.homologation}
                disabled={!isEdit && !isCreating}
                onChange={(e) => handleRowChange(index, "homologation", e.target.value)}
              />
            </Form.Group>
            {row.company && row.area && row.serie && (
              <Form.Group as={Col} md={2} className="mt-4">
              <Form.Check
                label="Tiene documento Padre"
                type="checkbox"
                disabled={!isEdit && !isCreating}
                checked={row.documentParent}
                onChange={(e) => handleRowChange(index, "documentParent", e.target.checked)}
              />
            </Form.Group>
            )}
          </Row>

          {row.documentParent && (
            <>
              {row.parentControls.map((parent, parentIndex) => (
                <Row key={parentIndex} className="mt-2">
                  <Reutilxx
                    mustShowCompany={false}
                    company={row.company}
                    handleChangeCompany={() => {}}
                    area={parent.AREAIDPA}
                    handleChangeArea={(value) => handleParentChange(index, parentIndex, "AREAIDPA", value)}
                    serie={parent.SERIEIPA}
                    handleChangeSerie={(value) => handleParentChange(index, parentIndex, "SERIEIPA", value)}
                    isSubmitting={isSubmitting}
                    isEdit={isEdit}
                    isCreating={isCreating}
                  />

                  <Form.Group as={Col} md={3}>
                    <Form.Label>Tipo Documental Padre</Form.Label>
                    <Form.Select
                      onChange={(e) => handleParentChange(index, parentIndex, "TIPOIDPA", e.target.value)}
                      value={parent.TIPOIDPA}
                      disabled={(!parent.SERIEIPA) || (!isEdit && !isCreating)}
                    >
                      <option value="">[SELECCIONE]</option>
                      {parent.TYPEOPTS.map((type) => (
                        <option key={type.TIPOIDXX} value={type.TIPOIDXX}>
                          {type.TIPODESX}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>

                  <Form.Group as={Col} md={2}>
                    <Form.Label>Metadato:*</Form.Label>
                    <Form.Select
                      onChange={(e) => handleParentChange(index, parentIndex, "METIDXXX", e.target.value)}
                      value={parent.METIDXXX}
                      disabled={(!parent.TIPOIDPA) || (!isEdit && !isCreating)} // Deshabilitado si no se selecciona un Tipo Documental Padre
                      required
                    >
                      <option value="">[SELECCIONE]</option>
                      {parent.METAOPTS.map((meta) => (
                        <option key={meta.METIDXXX} value={meta.METIDXXX}>
                          {meta.METDESXX}
                        </option>
                      ))}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">Debe Seleccionar un Metadato</Form.Control.Feedback>
                  </Form.Group>

                  { !(!isEdit && !isCreating) && (
                    <Form.Group as={Col} md={1} className="mt-4">
                    <Form.Label style={{paddingTop: "12px"}}>&nbsp;</Form.Label>
                      <span className="bi bi-plus-circle-fill p-2" style={{ color: "#fba500", fontsize: "1.5em" }}
                        onClick={() => addParent(index)}/>

                        { row.parentControls.length > 1 && (
                          <span className="bi bi-x-circle-fill" style={{ color: "#bdb9b3", fontsize: "1.5em"}}
                            onClick={() => removeParent(index, parentIndex)}/>
                          )
                        }
                    </Form.Group>
                  )}

                </Row>
              ))}
            </>
          )}
        </div>
      ))}
      {(isEdit || isCreating) && (
        <Row md={12} className="mt-3">
          <Col className="offset-10" md={1}>
            <Button type="Submit">Guardar</Button>
          </Col>
          {isCreating && (
            <Col md={1}>
              <Button
                variant="secondary"
                onClick={(e) => {
                  e.preventDefault();
                  resetForm();
                }}
              >
                Limpiar
              </Button>
            </Col>
          )}
        </Row>
      )}
      <Toast
        onClose={() => setShowToast(false)}
        show={showToast}
        delay={3000}
        autohide
        style={{
          position: 'absolute',
          top: 20,
          right: 20,
          zIndex: 9999,
        }}
      >
        <Toast.Header>
          <strong className="me-auto">Notificación</strong>
        </Toast.Header>
        <Toast.Body>{toastMessage}</Toast.Body>
      </Toast>
    </Form>
  );
};

export default Cretipxx;
