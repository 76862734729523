import React, { useState, useRef } from "react";
import Form from "react-bootstrap/Form";
import ExcelJS from 'exceljs';

const Excelxxx = ({ objectMetadata, setJsonmetx, bRequired }) => {
  const [errorMessage, setErrorMessage] = useState('');
  const fileInputRef = useRef(null);

  // Función encargada de manejar el contenido del archivo cargado
  const handleFile = (data) => {
    console.log(data);
    const firstColumnData = data.map(row => row[1]);
    // Limpiar los datos
    const filteredData = firstColumnData.filter(value => value !== undefined && value !== '');
    console.log(filteredData);
    const key = objectMetadata.METIDXXX;
    if (filteredData.length === 0) {
      setErrorMessage('El libro no contiene datos en la primera columna.');
      setJsonmetx(key, "");
      const fileInput = fileInputRef.current;
      fileInput.value = null;
    } else {
      setErrorMessage('');
      setJsonmetx(key, filteredData);
    }
  };

  // Función que maneja el cambio de archivo
  const handleChangeFile = (file) => {
    const fileExtension = file.name.split('.').pop().toLowerCase();
    const key = objectMetadata.METIDXXX;
    if (fileExtension !== 'xlsx' && fileExtension !== 'xls') {
      setErrorMessage('Extensión de archivo inválida. Solo se permiten archivos Excel.');
      setJsonmetx(key, "");
      const fileInput = fileInputRef.current;
      fileInput.value = null;
    } else {
      setErrorMessage('');
      const fileReader = new FileReader();

      fileReader.onload = async (e) => {
        const binaryStr = e.target.result;
        const workbook = new ExcelJS.Workbook();
        await workbook.xlsx.load(binaryStr);
        const worksheet = workbook.worksheets[0];
        const data = [];
        worksheet.eachRow((row, rowNumber) => {
          data.push(row.values);
        });
        handleFile(data);
      };

      fileReader.readAsArrayBuffer(file);
    }
  };

  return (
    <>
      <Form.Group controlId="fileUpload">
        <Form.Control
          type="file"
          accept=".xlsx,.xls"
          required={bRequired}
          ref={fileInputRef}
          isInvalid={!!errorMessage}
          onChange={(e) => {
            if (e.target.files.length > 0) {
              handleChangeFile(e.target.files[0]);
            }
          }}
        />
        <Form.Control.Feedback type="invalid">
          {errorMessage}
        </Form.Control.Feedback>
      </Form.Group>
    </>
  );
};

export default Excelxxx;
