import React, { useEffect, useState } from "react";
import { Button, ButtonGroup, Card, Col, Container, Form, Row } from "react-bootstrap";
import { useGetSeriesxFiltersMutation } from "../../libs/redux/slices/packagexSlice/packagexApiSlice";
import Metadatx from "./Metadatx";
import Linetime from "./Linetime";
import Modaview from "./Modaview";
import {
  getCustomFilters,
  getFiltersTkr,
  setFiltersTkr,
  getReports,
  setCustomFilters,
  setCliidxx,
} from "../../libs/redux/slices/Trkgfile/trkgfileSlice";

import {
  useGetFiltersQuery
} from "../../libs/redux/slices/Trkgfile/trkgfileApislice";
import { setDictionaryErrors } from "../../libs/redux/slices/errorSlice/errorSlice";
import { dictionaryErrors } from "./configxx";

import { useDispatch, useSelector } from "react-redux";
import Loadingx from "../Loadingx/Loadingx";
import InfiniteScroll from "react-infinite-scroll-component";
import CryptoJS from 'crypto-js';

const Selparxx = ({ onClose, EMPNITXX, AREAIDXX, TIPOIDXX, CLIIDXXX, DOCNROXX, parents, setFileParents }) => {
  const dispatch = useDispatch();
  dispatch(setDictionaryErrors({ dictionaryErrors }));
  const [series, setSeries] = useState([]);
  const [getSeriesByFilters] = useGetSeriesxFiltersMutation();
  
  const customFilters = useSelector(getCustomFilters);
  const { page, perPage, sorts } = customFilters;
  const [selAllChecked, setSelAllChecked] = useState([]);
  const [AllChecked, setAllChecked] = useState(false);
  const [checSelc, setChecSelc] = useState([]);
  const [dataTkr, setDatatkr] = useState([]);
  const [opMetada, setOpMetada] = useState(false);
  const [metadaSelc, setMetadaSelc] = useState("");
  const [linetime, setLinetime] = useState(false);
  const [lineData, setLineData] = useState("");
  const [metadaMaqSelc, setMetadaMaqSelc] = useState("");
  const reports = useSelector(getReports);
  const [selectedKey, setSelectedKey] = useState(0);
  const [dataEdit, setDataEdit] = useState(null);
  const [modalView, setModalView] = useState(false);
  const [jsonFilters, setJsonFilters] = useState("");
  const [currentImage, setCurrentImage] = useState("");
  const filtersByFormInfo = `filters[EMPNITXX]=${EMPNITXX}` +
    `&filters[AREAIDXX]=${AREAIDXX}` +
    `&filters[REGESTXX]=ACTIVO` +
    `&filtersIn[TIPOIDXX]=${parents.map((r) => { return r.TIPOIDPA }).join(",")}` + 
    `&filtersJson[JSONMETX->CLIIDXXX]=${CLIIDXXX}` +
    `&filtersJson[JSONMETX->DOCNROXX]=${DOCNROXX}`;

  const filters = useSelector(getFiltersTkr);
  setFiltersTkr(filtersByFormInfo);
  useEffect(() => {
    console.log("useEffect 1");
    fetchSeriesByFilters();
    //dispatch(setFiltersTkr(filtersByFormInfo));
    showBySeridxxx(0);
  // eslint-disable-next-line
  }, [EMPNITXX]);    

  const { data, isLoading, isFetching } = useGetFiltersQuery({
    filters,
    reports: reports,
    page,
    per_page: perPage,
    sorts,
  });
  useEffect(() => {
    console.log("useEffect 2");
    if (data && data?.data?.length > 0 && !reports) {
      const dataTkring = data.data.map((row) => {
        if (row.JSONMETX?.CLIIDXXX) {
          dispatch(setCliidxx(row.JSONMETX.CLIIDXXX))
        }
        switch (row.TYPEXXXX) {
          case "application/pdf":
            return { ...row, imgfilex: "images/Icon-PDF.png" };
          case "image/png":
          case "image/jpeg":
          case "image/jpg":
            let nomFile = row.PATHFILX.split("/").at(-1);
            let fileprev = nomFile.split(".").at(0) + `-prev.${nomFile.split(".").at(1)}`;
            let imagePrev = row.DOCDURLX.replace(nomFile, fileprev);
            return { ...row, imgfilex: "images/file-earmark-image.svg", IMGPREVX: imagePrev };
          case "text/xml":
          case "application/vnd.ms-excel":
          case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
            return { ...row, imgfilex: "images/icon-excel.png" };
          case "docx":
            return { ...row, imgfilex: "images/icon-microsoft-word.png" };
          case "video/mp4":
          case "audio/mpeg":
            return { ...row, imgfilex: "images/file-video.svg" };
          case "text/plain":
            return { ...row, imgfilex: "images/filetype-txt.svg" };
          default:
            return { ...row, imgfilex: "images/default-ext.svg" };
        }
      });
      const dataarr = data.data.map((rows, key) => {
        return (key = false);
      });
      setSelAllChecked(dataarr);
      //Validacion AREA 116
      const dataFilter = dataTkring.filter((row) => {
        let retorno = true;
        if (row.AREAIDXX === 116) {
          retorno = validateExpiredDocuments(row);
        }
        return retorno;
      });
      if (data.meta.current_page > 1) {
        setDatatkr((prevdata) => prevdata.concat(dataFilter));
      } else {
        setDatatkr(dataFilter);
      }
      setChecSelc([]);
      setAllChecked(false);
    } else {
      setDatatkr([]);
    }
    // eslint-disable-next-line
  }, [data, dispatch]);
  
  const validateExpiredDocuments = (row) => {
    const FECINICI = new Date();
    const FECHAFIN = row.JSONMETX["FECHAFIN"] ? new Date(`${row.JSONMETX["FECHAFIN"]}T23:59:00`) : FECINICI;
    return FECHAFIN >= FECINICI;
  }

  const fetchSeriesByFilters = async () => {
    return await getSeriesByFilters(filtersByFormInfo)
      .unwrap()
      .then((response) => {
        setSeries(response.data);
      })
      .catch((err) => {
        setSeries([]);
      });
  }

  const handleSubmit = (event) => {
    console.log(checSelc);
    console.log(parents);
    setFileParents((checSelc.map((row) => {
      return {
        IDARCHXX: row.IDARCHXX,
        METAKEYX: row.METAKEYS[(
          parents.filter((parent) => {
            return parent.AREAIDPA.toString() === row.AREAIDXX.toString() 
              && parent.SERIEIPA.toString() === row.SERIEIDX.toString()
              && parent.TIPOIDPA.toString() === row.TIPOIDXX.toString();
          })[0].METIDXXX
        )]??"",
        METAVALX: row.JSONMETX[(
          parents.filter((parent) => {
            return parent.AREAIDPA.toString() === row.AREAIDXX.toString() 
              && parent.SERIEIPA.toString() === row.SERIEIDX.toString()
              && parent.TIPOIDPA.toString() === row.TIPOIDXX.toString();
          })[0].METIDXXX
        )]??"SIN METADATO"
      }
    })));
    onClose(false);
  };

  const prevpage = (page) => {
    dispatch(setCustomFilters({ ...customFilters, page }));
  };

  const showBySeridxxx = (SERIEIDX) => {
    const page = 1;
    dispatch(setCustomFilters({ ...customFilters, page }));
    let finalfilts = `${filtersByFormInfo}`;
    setSelectedKey(parseInt(SERIEIDX));
    if(parseInt(SERIEIDX)!==0){
      finalfilts+= `&filters[SERIEIDX]=${SERIEIDX}`;
    }
    dispatch(setFiltersTkr(finalfilts));
  };

  /**
   * Funcion para abrir el Visor del Documento
   * @param dataRow
   */
  const viewFiles = (dataRow) => {
    if (dataRow && typeof dataRow === 'object' && 'TYPEXXXX' in dataRow && dataRow.TYPEXXXX === 'application/pdf') {
      // Convertir los datos a JSON
      const jsonData = JSON.stringify({
        url: dataRow.DOCDURLX,
        pEMPNITXX: dataRow.EMPNITXX,
        pAREAIDXX: dataRow.AREAIDXX,
        pSERIEIDX: dataRow.SERIEIDX,
        pTIPOIDXX: dataRow.TIPOIDXX
      });

      const encryptedData = CryptoJS.AES.encrypt(jsonData, 'GenioVisorPDF').toString();
      const urlBase = `/#/visor/${encodeURIComponent(encryptedData)}`;
      console.log(jsonData);
      console.log(urlBase);
      window.open(urlBase, '_blank');
    } else {
      window.open(dataRow.DOCDURLX, '_blank');
    }
  };

  const viewMetada = (meta, metaMaq) => {
    setMetadaSelc(meta);
    setMetadaMaqSelc(metaMaq);
    setOpMetada(true);
  };
  const viewLine = (dataline) => {
    setLineData(dataline);
    setLinetime(true);
  };

  const selChecked = (xKey, e) => {
    let arrchck = [];
    let data = JSON.parse(e.target.value);
    let value = data.IDARCHXX.toString();
    setAllChecked(false);
    const valArr = selAllChecked.map((rows, key) => {
      if (xKey === key) {
        return (key = !rows);
      } else {
        return (key = rows);
      }
    });
    if (checSelc.length > 0) {
      arrchck = checSelc.filter((itm) => {
        return itm.IDARCHXX.toString() !== value;
      });

      if (e.target.checked) {
        arrchck.push(data);
      }
    } else {
      arrchck.push(data);
    }

    setChecSelc(arrchck);
    setSelAllChecked(valArr);
  };
  const selAllCheck = () => {
    if (checSelc.length > 0) {
      setChecSelc([]);
    }
    const band = !AllChecked;
    setAllChecked(!AllChecked);
    const dataarr = dataTkr.map((rows, key) => {
      if (band) {
        //setChecSelc((idarch) => [...idarch, rows.IDARCHXX]);
        setChecSelc((idarch) => [...idarch, rows]);
      } else {
        setChecSelc([]);
      }
      return (key = band);
    });
    setSelAllChecked(dataarr);
  };

  const viewImages = (dataRow) => {
    let filts = '';
    if (Object.entries(dataRow.JSONMETX).length > 0) {
      for (let val in dataRow.JSONMETX) {
        if (val === "DOCNROXX" && typeof dataRow.JSONMETX[val] === 'object') {
          let nDocNros = dataRow.JSONMETX[val];
          for (let nDo of nDocNros) {
            filts += `filters[JSONMETX][->][${val}]=${nDo}&`
          };
        } else {
          if (dataRow.JSONMETX[val] !== "") {
            filts += `filters[JSONMETX][->][${val}]=${dataRow.JSONMETX[val]}&`
          }
        }
      }
      filts += "filters[REGESTXX]=ACTIVO&filters[TYPEXXXX][IN]=image/png,image/jpg,image/jpeg";
    }
    setJsonFilters(filts)
    setModalView(true);
    setCurrentImage(dataRow.DOCDURLX);
    setDataEdit(dataRow);
  };

  return (
    <>
      {/*{JSON.stringify(parents)}
      ************************
      ************************
      ************************
      {JSON.stringify(checSelc)}*/}
      <Row key="options">
        <ButtonGroup aria-label="Tipos">
          <Button 
            variant="primary"
            className={selectedKey===0?"button-group-selected-item":"button-group-item"}
            onClick={(e) => showBySeridxxx(0)}
          >GENERAL</Button>
          {series.map((item, index) => (
            <Button 
              variant="primary"
              className={selectedKey===parseInt(item.SERIEIDX)?"button-group-selected-item":"button-group-item"}
              onClick={(e) => showBySeridxxx(item.SERIEIDX)}
            >
              {item.SERIEDES}
            </Button>
          ))}
        </ButtonGroup>
      </Row>

      <Row key="select_all_1">
        <Col>
          <Form.Check label={"Seleccionar Todos"} onChange={() => selAllCheck()} checked={AllChecked} />
        </Col>
      </Row>
      <br></br>
      <Row key="pages">
        <Col className="d-flex justify-content-end">
          <span>Items por Pagina: {dataTkr.length}</span>
        </Col>
      </Row>
      <Row key="file_container">
        {(isLoading || isFetching) && page === 1 ? (
          <Loadingx />
        ) : dataTkr.length > 0 ? (
          <div id="scrollableDiv" style={{ height: "800px", overflowY: "scroll" }}>
            <InfiniteScroll
              dataLength={dataTkr.length}
              hasMore={data.meta.current_page < data.meta.last_page}
              next={() => {console.log("123"); prevpage(page + 1)}}
              loader={<Loadingx heigth={100} width={100} />}
              scrollableTarget="scrollableDiv"
            >
              {dataTkr.map((row, key) => {
                return (
                  <div
                    key={`pr${key}`}
                    className="p-3 col-7 col-md-4 col-lg-3 "
                    style={{ display: "inline-block" }}
                  >
                    <Card className="shadow rounded p-3" border="light" style={{ width: "15rem" }}>
                      <Container fluid>
                        <Row key={`pr-file-${key}`}>
                          <Col>
                            {row.TYPEXXXX === "image/png" ||
                              row.TYPEXXXX === "image/jpeg" ||
                              row.TYPEXXXX === "image/jpg" ? (
                              <img
                                variant="top"
                                src={`${process.env.REACT_APP_VISOR_WEB}/${row.imgfilex}`}
                                width="25"
                                height="35"
                                alt="imagen-files"
                              />
                            ) : (
                              <img
                                variant="top"
                                src={`${process.env.REACT_APP_VISOR_WEB}/${row.imgfilex}`}
                                width="50"
                                alt="imagen-files"
                              />
                            )}
                          </Col>
                          <Col className="d-grid justify-content-end">
                            <Form.Check
                              /*value={`${row.IDARCHXX}`}*/
                              value={JSON.stringify(row)}
                              onChange={(e) => selChecked(key, e)}
                              checked={selAllChecked[key]}
                            />
                          </Col>
                        </Row>
                        <Row key={`rw-file-${key}`}>
                          <Card.Body>
                            <Container>
                              {row.TYPEXXXX === "image/png" ||
                                row.TYPEXXXX === "image/jpeg" ||
                                row.TYPEXXXX === "image/jpg" ? (
                                <Row>
                                  <Col className="d-grid justify-content-center">
                                    <img variant="top" src={`${row.IMGPREVX}`} alt="No se encontro Prev-Imagen" />
                                  </Col>
                                </Row>
                              ) : (
                                ""
                              )}
                              <Row key={`des${key}`}>
                                <Col className="col-12 text-doc p-0">
                                  <strong>TIPO</strong> : {row.TIPODESX}
                                </Col>
                              </Row>
                              {Object.keys(row.METADATX).map((item, key) => {
                                if (key <= 3) {
                                  let typeMet = "";
                                  typeof row.METADATX[item] === "object" && row.METADATX[item] !== null
                                    ? (typeMet = row.METADATX[item].join(", "))
                                    : (typeMet = row.METADATX[item]);
                                  return (
                                    <Row key={key}>
                                      <Col className="col-12 text-doc p-0">
                                        <strong>{item}</strong> : {typeMet}
                                      </Col>
                                    </Row>
                                  );
                                } else {
                                  return "";
                                }
                              })}
                              <Row key={key}>
                                <Col className="col-12 text-doc p-0">
                                  <strong>FECHA</strong> : {row.REGFECXX} / {row.REGHORXX}
                                </Col>
                              </Row>
                            </Container>
                          </Card.Body>
                        </Row>
                        <Row key={`pr-opts-${key}`}>
                          <Col>
                            <img
                              onClick={() => viewLine(row)}
                              src={`${process.env.REACT_APP_VISOR_WEB}/images/icon-timeline.png`}
                              height={30}
                              alt="time line"
                              style={{ cursor: "pointer" }}
                            />
                          </Col>
                          <Col>
                            <img
                              onClick={() => viewMetada(row.METADATX, row.JSONMETX)}
                              variant="top"
                              src={`${process.env.REACT_APP_VISOR_WEB}/images/icon-info.png`}
                              height={30}
                              alt="info"
                              style={{ cursor: "pointer" }}
                            />
                          </Col>
                          {row.TYPEXXXX === "image/png" ||
                            row.TYPEXXXX === "image/jpeg" ||
                            row.TYPEXXXX === "image/jpg" ? (
                            <Col>
                              <img
                                onClick={() => viewImages(row)}
                                variant="top"
                                src={`${process.env.REACT_APP_VISOR_WEB}/images/icon-view.png`}
                                height={30}
                                alt="view"
                                style={{ cursor: "pointer" }}
                              />
                            </Col>
                          ) : (
                            <Col>
                              <img
                                onClick={() => viewFiles(row)}
                                variant="top"
                                src={`${process.env.REACT_APP_VISOR_WEB}/images/icon-view.png`}
                                height={30}
                                alt="view"
                                style={{ cursor: "pointer" }}
                              />
                            </Col>
                          )
                          }
                        </Row>
                      </Container>
                    </Card>
                  </div>
                );
              })}
            </InfiniteScroll>
          </div>
        ) : (
          ""
        )}
      </Row>
      {opMetada && (
        <Metadatx
          opMetada={opMetada}
          setOpMetada={setOpMetada}
          metadaSelc={metadaSelc}
          setMetadaSelc={setMetadaSelc}
          metadaMaqSelc={metadaMaqSelc}
        />
      )}
      {linetime && (
        <Linetime linetime={linetime} setLinetime={setLinetime} lineData={lineData} setLineData={setLineData} />
      )}
      {modalView && jsonFilters && (<Modaview modalView={modalView} setModalView={setModalView} jsonFilters={jsonFilters} currentImage={currentImage} dataEdit={dataEdit} />)}
      <br></br>
      <Row key="select_all_2">
        <Col>
          <Form.Check label={"Seleccionar Todos"} onChange={() => selAllCheck()} checked={AllChecked} />
        </Col>
      </Row>
      <Row md={12} className="mt-3">
        <Col className="offset-10" md={1}>
          <Button onClick={handleSubmit}>Guardar</Button>
        </Col>
      </Row>
    </>
  )  
};

export default Selparxx;
